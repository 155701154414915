<div class="title">
  <h1>{{ 'watch.title' | translate }}</h1>
</div>

<div class="container" coreActivitySection="SectionsGrid">
  <div class="item__card" (click)="setState('tv')"  coreUserActivityClickTracking="ShowJATV">
    <div class="item__card__image">
      <img src="/images/watch/tv.svg" alt="">
    </div>
    <h3>Jack’s TV</h3>
  </div>

  <div class="item__card" (click)="setState('chalkboards')" coreUserActivityClickTracking="ShowChalkboards">
    <div class="item__card__image">
      <img src="/images/watch/chalkboards.svg" alt="">
    </div>
    <h3>chalkboards</h3>
  </div>

  <div class="item__card" (click)="setState('sport')" coreUserActivityClickTracking="ShowSport">
    <div class="item__card__image">
      <img src="/images/watch/sports.svg" alt="">
    </div>
    <h3>sports</h3>
  </div>
</div>

@if (state === 'chalkboards') {
  <app-chalkboards [slides]="slides"/>
}

@if (state === 'tv') {
  <app-jaks-video [videos]="videos"/>
}

@if (state === 'sport') {
  <app-jaks-sports/>
}
