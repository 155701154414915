import { Component } from '@angular/core';
import { QrViewComponent } from '@app/components/qr-view/qr-view.component';
import { ActivitySectionDirective, UserActivityClickTrackingDirective } from '@core';

@Component({
  selector: 'app-get-rewarded-tab',
  templateUrl: './get-rewarded-tab.component.html',
  imports: [
    QrViewComponent,
    ActivitySectionDirective,
    UserActivityClickTrackingDirective,
  ],
  styleUrls: ['./get-rewarded-tab.component.scss'],
})
export class GetRewardedTabComponent {
  public isQRVisible = false;
  public title = '';
  public description: string | undefined;
  public imgSrc = '';

  showQRCode(title: string, imgSrc: string, description?: string): void {
    this.isQRVisible = true;
    this.title = title;
    this.imgSrc = imgSrc;

    if (description) {
      this.description = description;
    }
  }
}
