@if (isLoading()) {
  <app-loading/>
} @else {
  @if (isTabsShow) {
    <div class="tabs" [class.fixed]="tabs.isSticky" coreActivitySection="MenuCategories">
      @if (isBackTabShow) {
        <button
          class="tab tab__full"
          [class.tab__main] = "tabs.activeId === 'tabMain'"
          [class.tab__happy_hours] = "tabs.activeId === 'tabHappyHours'"
          (click)="setActiveTab(tabs.activeId)"
          coreUserActivityClickTracking="BackToCategory"
        >
          ◁ {{ 'menu.tabs.backToCategory' | translate }}
        </button>
      } @else {
        <button
          class="tab tab__main"
          [class.tab__active]="tabs.activeId === 'tabMain'"
          (click)="setActiveTab('tabMain')"
          coreUserActivityClickTracking="ShowMainMenus"
        >
          {{ 'menu.tabs.main' | translate }}
        </button>
        <button
          class="tab tab__happy_hours"
          [class.tab__active]="tabs.activeId === 'tabHappyHours'"
          (click)="setActiveTab('tabHappyHours')"
          coreUserActivityClickTracking="ShowHappyHoursMenus"
        >
          {{ 'menu.tabs.happyHours' | translate }}
        </button>
      }
      <span class="glider"></span>
    </div>
  }

  @if (tabs.activeId === 'tabMain') {
    <div class="box-menus" coreActivitySection="MenuMain">
      @if (!mainMenusCurrent) {
        <div class="categories" [class.categories__tabs]="isTabsShow">
          <div class="categories__title">
            <h2>Menu</h2>
          </div>
          @for (menu of mainMenus; track menu.id) {
            <button
              class="category"
              (click)="setMainMenusCurrent(menu)"
              [coreUserActivityClickTracking]="menu.name.split(' ').join('') + 'Open'"
            >
              {{ menu.name }}
            </button>
          } @empty {
            @if (isLoading() || isSyncInProgress()) {
              <app-loading/>
            } @else {
              <div class="empty" >
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.25 5V8.75C6.25 9.74456 6.64509 10.6984 7.34835 11.4017C8.05161 12.1049 9.00544 12.5 10 12.5C10.9946 12.5 11.9484 12.1049 12.6517 11.4017C13.3549 10.6984 13.75 9.74456 13.75 8.75V5M23.75 3.75V18.75H17.5C17.4712 14.1488 17.73 9.4925 23.75 3.75ZM23.75 18.75V26.25H22.5V22.5L23.75 18.75ZM10 5V26.25V5Z" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                </svg>
                <div>{{ 'menu.empty' | translate }}</div>
              </div>
            }
          }
        </div>
      } @else {
        <div class="menu" [class.menu__tabs]="isTabsShow">
          @for (url of mainMenusCurrent.pagesLocal; track url) {
            <core-menu-image [url]="url"/>
          }
        </div>
      }
    </div>
  } @else {
    <div class="box-menus" coreActivitySection="MenuHappyHours">
      @if (!happyHoursMenusCurrent) {
        <div class="categories" [class.categories__tabs]="isTabsShow">
          <div class="categories__title">
            <h2>Menu</h2>
          </div>
          @for (menu of happyHoursMenus; track menu.id) {
            <button
              class="category"
              (click)="setHappyHoursMenusCurrent(menu)"
              [coreUserActivityClickTracking]="menu.name.split(' ').join('') + 'Open'"
            >
              {{ menu.name }}
            </button>
          } @empty {
            @if (isLoading() || isSyncInProgress()) {
              <app-loading/>
            } @else {
              <div class="empty" >
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.25 5V8.75C6.25 9.74456 6.64509 10.6984 7.34835 11.4017C8.05161 12.1049 9.00544 12.5 10 12.5C10.9946 12.5 11.9484 12.1049 12.6517 11.4017C13.3549 10.6984 13.75 9.74456 13.75 8.75V5M23.75 3.75V18.75H17.5C17.4712 14.1488 17.73 9.4925 23.75 3.75ZM23.75 18.75V26.25H22.5V22.5L23.75 18.75ZM10 5V26.25V5Z" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                </svg>
                <div>{{ 'menu.empty' | translate }}</div>
              </div>
            }
          }
        </div>
      } @else {
        <div class="menu" [class.menu__tabs]="isTabsShow">
          @for (url of happyHoursMenusCurrent.pagesLocal; track url) {
            <core-menu-image [url]="url"/>
          }
        </div>
      }
    </div>
  }
}

