<div class="back-navigation" coreActivitySection="QRView">
  <button class="back" coreUserActivityClickTracking="Back">
    ◁ Back to list
  </button>
</div>

<div class="box">
  <ng-container>
    <div class="title">
      {{ title() }}
    </div>
    <img [src]="imgSrc()" alt="">
    <div class="description">
      @if (description(); as description) {
        {{ description }}
      } @else {
        Scan & Play
      }
    </div>
  </ng-container>
</div>
