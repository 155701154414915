import { Component, input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-chalkboards',
  standalone: true,
  templateUrl: './chalkboards.component.html',
  styleUrls: ['./chalkboards.component.scss'],
})
export class ChalkboardsComponent implements OnInit, OnDestroy {

  public readonly slides = input<string[]>([]);

  imgUrl = '';
  currentIndex = 0;
  timeoutId: number | null = null;

  public ngOnInit(): void {
    this.showSlide();
  }

  public ngOnDestroy(): void {
    this.stopSlider();
  }

  public showSlide(): void {
    this.imgUrl = this.slides()[this.currentIndex];
    this.currentIndex = (
      this.currentIndex + 1
    ) % this.slides().length;

    // @ts-ignore
    this.timeoutId = setTimeout(() => {
      this.showSlide();
    }, 5000);
  }

  public stopSlider(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

}
