import { ChangeDetectionStrategy, Component, computed, DestroyRef, Inject } from '@angular/core';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { merge, of, shareReplay, take } from 'rxjs';
import { distinctUntilChanged, filter, map, skip, switchMap } from 'rxjs/operators';
import {
  ActivitySectionDirective,
  CORE_FEATURE_TOGGLE,
  CoreFeatureToggle,
  InteractionService,
  InteractsPresentComponent,
  UserActivityClickTrackingDirective,
  WidgetUiConfigRef,
} from '@core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
  imports: [
    RouterLink,
    TranslateModule,
    RouterLinkActive,
    ActivitySectionDirective,
    UserActivityClickTrackingDirective,
    InteractsPresentComponent,
  ],
  styleUrls: ['./tab-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabBarComponent {

  private readonly currentUrl$ = merge(
    of(this.router.url),
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event) => event.urlAfterRedirects),
    ),
  ).pipe(
    distinctUntilChanged(),
    shareReplay(1),
  );

  private readonly pageIs$ = this.currentUrl$.pipe(
    map((url) => {
      const result = {
        home: false,
        play: false,
        rewarded: false,
        watch: false,
        more: false,
      };

      if (url === '/main/home') {
        result.home = true;
      }
      else if (url === '/main/play') {
        result.play = true;
      }
      else if (url === '/main/rewarded') {
        result.rewarded = true;
      }
      else if (url === '/main/watch') {
        result.watch = true;
      }
      else if (url === '/main/more') {
        result.more = true;
      }

      return result;
    }),
    shareReplay(1),
  );

  public readonly pageIs = toSignal(this.pageIs$, {
    requireSync: true,
  });

  public readonly isIdle = toSignal(this.interaction.idle$, {
    requireSync: true,
  });

  public readonly hideTabBar = computed(() => {
    if (this.widgetUiConfig.tabBarAutoHide) {
      return this.isIdle();
    }

    return false;
  });

  constructor(
    @Inject(CORE_FEATURE_TOGGLE) private readonly featureToggle: CoreFeatureToggle,
    private readonly destroyRef: DestroyRef,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly interaction: InteractionService,
    private readonly widgetUiConfig: WidgetUiConfigRef,
  ) {
    this.interaction.idle$.pipe(
      distinctUntilChanged(),
      skip(1),
      switchMap((idle) => this.pageIs$.pipe(
        take(1),
        map((pageIs) => {
          return { idle, isHomePage: pageIs.home };
        }),
      )),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(({ idle, isHomePage }) => {
      if (!idle) {
        if (isHomePage) {
          this.router.navigate(this.featureToggle.idleClickGoTo).then();
        }
      }
      else {
        if (!isHomePage) {
          this.router.navigate(['/main/home']).then();
        }

        if (this.translate.currentLang !== this.translate.defaultLang) {
          this.translate.use(this.translate.defaultLang);
        }
      }
    });
  }

}
