<div class="title">
  <img src="/images/rewarded/inspired.png" alt="">
</div>

<div class="container" coreActivitySection="AppLinks">
  <div class="container__links">
    <div class="container__links__demo">
      <img src="/images/rewarded/mock.png" alt="">
    </div>
    <h3><span>Waiting Sucks.</span> So members don’t wait.</h3>
    <span class="download">Tap here to download:</span>
    <div class="container__links__elements">
      <img
        (click)="showQRCode('Scan & download', '/images/qr/appStore.svg', 'Waiting Sucks. So members don’t wait')"
        src="/images/rewarded/app.svg"
        alt="appStore"
        coreUserActivityClickTracking="OpenQRAppStore"
      />
      <img
        (click)="showQRCode('Scan & download', '/images/qr/google.svg', 'Waiting Sucks. So members don’t wait')"
        src="/images/rewarded/google.svg"
        alt="googlePlay"
        coreUserActivityClickTracking="OpenQRGooglePlay"
      />
    </div>
  </div>

  <div class="container__brands">
    <img src="/images/rewarded/jacks.png" alt="">
    <img src="/images/logo.svg" alt="">
    <img src="/images/rewarded/scaddabush.png" alt="">
    <img src="/images/rewarded/reds.png" alt="">
    <img src="/images/rewarded/dukes.png" alt="">
    <img src="/images/rewarded/lose.png" alt="">
  </div>
</div>

@if (isQRVisible) {
  <app-qr-view
    (click)="isQRVisible = !isQRVisible"
    [title]="title"
    [imgSrc]="imgSrc"
    [description]="description"
  />
}
