import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import type { IMatchDetail, IResponse, ITeam, MatchConfig, SportType } from './jaks-sports.types';
import { AsyncPipe } from '@angular/common';
import { LoadingComponent } from '../loading/loading.component';
import { ActivitySectionDirective, UserActivityClickTrackingDirective } from '@core';

const WIDGET_HOST = 'https://widgets.thesports01.com/en';
const MATCH_CONFIG: MatchConfig = {
  ice_hockey: {
    widgetProfileId: 'y0b7dps8hn5v84xn',
    version: '2d',
    statusesIgnore: [100, 105, 110, 0],
  },
  baseball: {
    widgetProfileId: 'l2s4k3cdhpv3gz6d',
    statusesIgnore: [],
  },
  basketball: {
    widgetProfileId: 'p4tdkofeh6489v4d',
    version: '2d',
    statusesIgnore: [100, 105, 110, 0],
  },
};

@Component({
  selector: 'app-jaks-sports',
  templateUrl: './jaks-sports.component.html',
  imports: [
    AsyncPipe,
    LoadingComponent,
    UserActivityClickTrackingDirective,
    ActivitySectionDirective,
  ],
  styleUrls: ['./jaks-sports.component.scss'],
})
export class JaksSportsComponent implements OnInit {

  public type$ = new BehaviorSubject<SportType>('ice_hockey');

  public loading = false;
  public currentMatch: IMatchDetail | null = null;
  public currentWidgetUrl: SafeResourceUrl | null = null;

  public matches: IMatchDetail[] = [];

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.type$.pipe(
      tap(() => {
        this.loading = true;
        this.matches = [];
        this.currentMatch = null;
        this.currentWidgetUrl = null;
      }),
      switchMap((type) => {
        return this.http.get<IResponse>(`@api_host/proxy/thesports/v1/${ type }/match/diary`);
      }),
    ).subscribe((response) => {
      const findTeam = (id: string) => response.results_extra.team.find((team) => team.id === id);

      this.matches = response.results.filter(
        (match) => !MATCH_CONFIG[this.type$.getValue()].statusesIgnore.includes(match.status_id)
          && match.coverage.mlive
          === 1,
      ).map((match) => (
        {
          ...match,
          date: new Date(match.match_time * 1000),
          home_team: findTeam(match.home_team_id) as ITeam,
          away_team: findTeam(match.away_team_id) as ITeam,
        }
      ));

      this.loading = false;
    });
  }

  public selectType(type: SportType): void {
    this.type$.next(type);
  }

  public selectMatch(match: IMatchDetail): void {
    this.currentMatch = match;
    this.setCurrentMatchWidgetUrl(match);
  }

  public setCurrentMatchWidgetUrl(match: IMatchDetail): void {
    const type = this.type$.getValue();
    const widgetType = type.replace('_', '-');
    const config = MATCH_CONFIG[type];

    const url = new URL(`${ WIDGET_HOST }/${ config.version ? config.version + '/' : '' }${ widgetType }`);
    url.searchParams.set('profile', config.widgetProfileId);
    url.searchParams.set('uuid', match.id);

    this.currentWidgetUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url.toString());
  }

}
