import { Routes } from '@angular/router';
import { MainPageComponent } from './pages';
import { MenuTabComponent } from './pages/main-page/menu-tab/menu-tab.component';
import { HomeTabComponent } from './pages/main-page/home-tab/home-tab.component';
import { SpecialOfferComponent } from './pages/main-page/special-offer/special-offer.component';
import { PlayTabComponent } from './pages/main-page/play-tab/play-tab.component';
import { WatchTabComponent } from './pages/main-page/watch-tab/watch-tab.component';
import { MoreTabComponent } from './pages/main-page/more-tab/more-tab.component';
import { GetRewardedTabComponent } from './pages/main-page/get-rewarded-tab/get-rewarded-tab.component';

export const appRoutes: Routes = [
  {
    path: 'main',
    component: MainPageComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'menu',
        component: MenuTabComponent,
        data: { animation: 'MenuTab', screen: 'Menu' },
      },
      {
        path: 'home',
        component: HomeTabComponent,
        data: { animation: 'HomeTab', screen: 'Home' },
        children: [
          {
            path: 'special-offer',
            component: SpecialOfferComponent,
            data: { animation: 'SpecialOffer', screen: 'SpecialOffer' },
          },
        ],
      },
      {
        path: 'play',
        component: PlayTabComponent,
        data: { animation: 'PlayTab', screen: 'Play' },
      },
      {
        path: 'watch',
        component: WatchTabComponent,
        data: { animation: 'WatchTab', screen: 'Watch' },
      },
      {
        path: 'rewarded',
        component: GetRewardedTabComponent,
        data: { animation: 'GetRewardedTab', screen: 'GetRewarded' },
      },
      {
        path: 'more',
        component: MoreTabComponent,
        data: { animation: 'MoreTab', screen: 'MoreTab' },
      },
    ],
  },
];
