<div class="title">
  <h1>{{ 'more.title' | translate }}</h1>
</div>

<div class="container" coreActivitySection="Sections">
  <div class="item__card"
       routerLink="/main/menu">
    <div class="item__card__image">
      <img src="/images/more/menu.svg" alt="">
    </div>
    <h3>Menu</h3>
  </div>

  <div
    class="item__card"
    (click)="showQRCode('Pay Bill', '/images/qr/payBill.svg', 'Pay your bill without your server via our Inspired Rewards app')"
    coreUserActivityClickTracking="ShowPayBill"
  >
    <div class="item__card__image">
      <img src="/images/more/payBill.svg" alt="">
    </div>
    <h3>Pay Bill</h3>
  </div>

  <div
    class="item__card"
    (click)="showQRCode('Review Us', '/images/qr/revievUs.svg', 'Scan to Leave Us a Google Review')"
    coreUserActivityClickTracking="ShowReviewUs"
  >
    <div class="item__card__image">
      <img src="/images/more/reviewUs.svg" alt="">
    </div>
    <h3>Review Us</h3>
  </div>

  <div
    class="item__card"
    (click)="showQRCode('Buy a Gift Card', '/images/qr/gift.svg', 'Since you’re already here, buy a gift card and save some money!')"
    coreUserActivityClickTracking="ShowBuyGiftCard"
  >
    <div class="item__card__image">
      <img src="/images/more/buyCard.svg" alt="">
    </div>
    <h3>Buy a Gift Card</h3>
  </div>
</div>

@if (isQRVisible) {
  <app-qr-view
    (click)="isQRVisible = !isQRVisible"
    [title]="title"
    [imgSrc]="imgSrc"
    [description]="description"
  />
}
