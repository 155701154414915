<div class="title">
  <h1>{{ 'play.title' | translate }}</h1>
</div>

<div class="game__list" coreActivitySection="GameList">
  <div
    class="game__list__card"
    (click)="showQRCode('Trivia', '/images/qr/trivia.svg')"
    coreUserActivityClickTracking="ShowTrivia"
  >
    <img src="/images/game/trivia.jpg" alt="Trivia">
    <div class="description">
      <h3>Trivia</h3>
      <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</span>
    </div>
  </div>

  <div
    class="game__list__card"
    (click)="showQRCode('Axe Master', '/images/qr/axe.svg')"
    coreUserActivityClickTracking="ShowAxeMaster"
  >
    <img src="/images/game/axeMaster.jpg" alt="Axe Master">
    <div class="description">
      <h3>Axe Master</h3>
      <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</span>
    </div>
  </div>

  <div
    class="game__list__card"
    (click)="showQRCode('Battleship', '/images/qr/battleship.svg')"
    coreUserActivityClickTracking="ShowBattleship"
  >
    <img src="/images/game/battleship.jpg" alt="Battleship">
    <div class="description">
      <h3>Battleship</h3>
      <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</span>
    </div>
  </div>

  <div
    class="game__list__card"
    (click)="showQRCode('Brick Breaker', '/images/qr/brick.svg')"
    coreUserActivityClickTracking="ShowBrickBreaker"
  >
    <img src="/images/game/brick.jpg" alt="Brick Breaker">
    <div class="description">
      <h3>Brick Breaker</h3>
      <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</span>
    </div>
  </div>

  <div
    class="game__list__card"
    (click)="showQRCode('Knock Down Cans', '/images/qr/knock.svg')"
    coreUserActivityClickTracking="ShowKnckDownCans"
  >
    <img src="/images/game/knock.jpg" alt="Knock Down Cans">
    <div class="description">
      <h3>Knock Down Cans</h3>
      <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</span>
    </div>
  </div>

  @if (isQRVisible) {
    <app-qr-view
      (click)="isQRVisible = !isQRVisible"
      [title]="gameName" [imgSrc]="imgSrc"
    />
  }
</div>
