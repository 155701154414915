import { mainPageAnimation } from './main-page.animation';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { BackgroundPlayerComponent } from '@core';
import { TabBarComponent } from '@app/components/tab-bar/tab-bar.component';

@Component({
  selector: 'app-main',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
  animations: [
    mainPageAnimation,
  ],
  imports: [
    RouterOutlet,
    TabBarComponent,
    BackgroundPlayerComponent,
  ]
})
export class MainPageComponent implements OnInit {

  constructor(
    private readonly changeRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.changeRef.detectChanges();
  }

  public prepareRoute(outlet: RouterOutlet): unknown {
    return outlet?.activatedRouteData['animation'];
  }

}
