import { AfterViewInit, Component, ElementRef, input, viewChild } from '@angular/core';

@Component({
  selector: 'app-jaks-video',
  templateUrl: './jaks-video.component.html',
  imports: [],
  styleUrls: ['./jaks-video.component.scss'],
})
export class JaksVideoComponent implements AfterViewInit {

  public readonly videos = input<string[]>([]);

  currentVideoIndex = 0;
  currentVideoPlayer!: ElementRef;
  loading = true;

  private readonly videoPlayer1 = viewChild.required<ElementRef<HTMLVideoElement>>('videoPlayer1');
  private readonly videoPlayer2 = viewChild.required<ElementRef<HTMLVideoElement>>('videoPlayer2');

  ngAfterViewInit(): void {
    const { index, startTime } = this.calculateVideoAndStartTime(60, this.videos().length);
    this.currentVideoIndex = index;
    const videoPlayer1 = this.videoPlayer1();
    videoPlayer1.nativeElement.src = this.videos()[this.currentVideoIndex];
    videoPlayer1.nativeElement.currentTime = startTime;
    videoPlayer1.nativeElement.play();
    this.currentVideoPlayer = videoPlayer1;
    const videoPlayer2 = this.videoPlayer2();
    videoPlayer2.nativeElement.src = this.videos()[(
      this.currentVideoIndex + 1
    ) % this.videos().length];
    videoPlayer2.nativeElement.pause();

    this.currentVideoPlayer.nativeElement.addEventListener('loadeddata', () => {
      this.loading = false;
    });
  }

  playNextVideo(): void {
    if (this.currentVideoIndex < this.videos().length - 1) {
      this.currentVideoIndex++;
    }
    else {
      this.currentVideoIndex = 0;
    }

    const videoPlayer1 = this.videoPlayer1();
    const nextVideoPlayer = this.currentVideoPlayer === videoPlayer1 ? this.videoPlayer2() : videoPlayer1;
    this.currentVideoPlayer.nativeElement.style.display = 'none';
    nextVideoPlayer.nativeElement.style.display = 'block';
    nextVideoPlayer.nativeElement.play();
    this.currentVideoPlayer.nativeElement.src = this.videos()[(
      this.currentVideoIndex + 1
    ) % this.videos().length];
    this.currentVideoPlayer.nativeElement.pause();
    this.currentVideoPlayer = nextVideoPlayer;
  }

  calculateVideoAndStartTime(videoDuration: number, numberOfVideos: number): { index: number; startTime: number } {
    const currentTime = Date.now() / 1000;

    const totalDuration = videoDuration * numberOfVideos;
    const elapsedTime = currentTime % totalDuration;
    const index = Math.floor(elapsedTime / videoDuration);
    const startTime = elapsedTime - index * videoDuration;

    return { index, startTime };
  }
}
